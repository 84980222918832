import "twin.macro"
import { Link } from "gatsby"
import { SEO, LayoutNotFoundPage } from "../components"
import { Button } from "../elements"

const NotFoundPage = () => {
  return (
    <LayoutNotFoundPage>
      <SEO title="404 - Seite nicht gefunden" />
      <div tw="text-white text-center">
        <h1 tw="mt-4 font-bold text-2xl">
          Diese Seite wurde leider nicht gefunden
        </h1>
        <Button tw="mt-8" as={Link} to="/">
          Zur Startseite
        </Button>
      </div>
    </LayoutNotFoundPage>
  )
}

export default NotFoundPage
